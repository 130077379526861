import { useEffect, useCallback } from 'react';
import moment from 'moment';
import { useRouter } from 'next/router';
import { t, getLanguage } from './ReactSwitchLangWrapper';
import { moveFocusTo, setScreenReaderAlert } from './Accessibility';
import { events, logAmpEvent } from './Amplitude';

export function scrollToTop(focusID = 'main-cont') {
  window.scrollTo(0, 0);
  moveFocusTo(focusID);
}

export function useFormExitConfirm(
  msgKey,
  logoutMsgKey,
  confirmLogoutOnly = false,
  showAlert = true
) {
  const router = useRouter();
  const confirmationMsg = t(msgKey).replaceAll('\\n', '\n');

  const handleRouteChange = (useCallback((url) => {
    if (!showAlert) return;
    const isLogout = url.includes('logout');
    const msg = isLogout ? t(logoutMsgKey).replaceAll('\\n', '\n') : confirmationMsg;

    // eslint-disable-next-line no-alert
    if ((!confirmLogoutOnly || isLogout) && !window.confirm(msg)) {
      // have to throw literal to prevent nextjs from complaining about uncaught runtime exception
      throw 'User aborted redirect.'; // eslint-disable-line no-throw-literal
    }
  }, [showAlert, confirmLogoutOnly, confirmationMsg, logoutMsgKey]));

  const handleUnload = (useCallback((e) => {
    if (!showAlert) return;
    e.preventDefault();
    e.returnValue = confirmationMsg;
  }, [showAlert, confirmationMsg]));

  useEffect(() => {
    if (confirmLogoutOnly) {
      router.events.on('routeChangeStart', handleRouteChange);
      return () => router.events.off('routeChangeStart', handleRouteChange);
    }

    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleRouteChange, confirmLogoutOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  return () => {
    router.events.off('routeChangeStart', handleRouteChange);
    window.removeEventListener('beforeunload', handleUnload);
  };
}

export function invalidFormAlert(invalid, errors, singleError = false) {
  let checkCount = 0;
  let alertString = '';
  // ignore error key "_"; this is used in
  // first-time submit errors (warnings) where users can proceed by resubmitting
  const errorFields = Object.keys(errors).filter((name) => name !== '_');
  if (invalid && errors && errorFields.length > 0) {
    // focus on first invalid element
    const focusElem = document.getElementsByName(errorFields[0])[0];
    focusElem.focus();
    focusElem.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // loop though all validation errors
    errorFields.forEach((item) => {
      if (!singleError) {
        if (errors[item] !== true) {
          const e = document.getElementById(`${item}Lbl`).firstChild.textContent;
          alertString += `${e}, `;
        } else {
          checkCount += 1;
        }
      }
      const itemFormatted = item.replace(/[0-9]/g, '').replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
      logAmpEvent(events.USER_ENTERED_INVALID_VALUE, {
        Field: (itemFormatted.charAt(0).toUpperCase() + itemFormatted.slice(1)).replace(/\s+/g, ' '),
        Reason: errors[item] !== true ? errors[item] : 'Required',
      });
    });
    if (singleError) {
      setScreenReaderAlert(Object.values(errors)[0]);
    } else {
      setScreenReaderAlert(checkCount === 0 ?
        t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString) :
        `${t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString)} ${t('ScreenReaderAlert_CheckAllCheckboxes').replace('xCount', checkCount)}`);
    }
  }
}

/**
 * If this function returns empty string, return from onSubmit (but keep loading state to true)
 * and let the ReCAPTCHA component's onChange callback trigger a resubmit.
 * When called during resubmit, a valid token will be returned by this function
 * so continue form submission with that token
 *
 * @param {*} reCaptchaRefValue the `.current` value of the ref passed into the ReCAPTCHA component
 * @param {() => *} onAbort callback to execute when user aborts reCAPTCHA challenge
 * @returns the reCAPTCHA token or empty string
 */
export function getReCaptchaToken(reCaptchaRefValue, onAbort) {
  // find the reCAPTCHA challenge window
  const list = document.querySelectorAll("iframe[src*='google.com/recaptcha/api2/bframe']");
  if (list.length > 0) {
    const recaptchaWindow = list[list.length - 1].parentNode.parentNode;

    new MutationObserver(([{ target }], observer) => {
      if (target.style.opacity === '0') { // challenge window closed
        logAmpEvent(events.GOOGLE_RECAPTCHA_CANCEL);
        onAbort();
        observer.disconnect();
      }
    }).observe(recaptchaWindow, { attributeFilter: ['style'] });
  }

  const reCaptchaToken = reCaptchaRefValue.getValue();

  if (!reCaptchaToken) {
    reCaptchaRefValue.executeAsync();
  } else {
    // reCaptchaToken will be sent to the API and consumed, so a new token is
    // required for the next submit. Reset in advance to make sure the
    // abort handler is attached to the new window.
    // Mutation observer attached to the previous window will also be disposed here
    reCaptchaRefValue.reset();
  }

  return reCaptchaToken;
}

export function formatCurrency(amount, omitDecimal) {
  const lang = getLanguage();
  const formatter = new Intl.NumberFormat(`${lang}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: omitDecimal ? 0 : 2,
    maximumFractionDigits: omitDecimal ? 0 : 2,
  });

  return formatter.format(amount);
}

export function formatNumber(num) {
  const lang = getLanguage();
  return num.toLocaleString(lang);
}

/**
 * Pass in the form values object to this function before validating/submitting them
 * to normalize leading/trailing spaces and iOS single quotes.
 * @returns {void} undefined; the parameter object will be modified in place
 */
export function normalizeFormValues(values) {
  Object.keys(values).forEach((key) => {
    if (typeof values[key] === 'string') {
      // eslint-disable-next-line no-param-reassign
      values[key] = values[key].trim().replaceAll(/[‘’]/g, "'");
    }
  });
}

export function checkIfNullString(value) {
  return value || '';
}

export function initiateCheckbox(value) {
  if (value === true) return 'true';
  if (value === false) return 'false';
  return undefined;
}

export function getCheckboxData(value) {
  if (value === 'true' || value === 'false') return value;
  return '';
}

export function formatDateToHumanReadable(date) {
  const lang = getLanguage();
  const mDate = moment(date);
  if (lang === 'fr') {
    return mDate.format('D MMMM YYYY');
  }
  return mDate.format('MMMM D, YYYY');
}

export const convertBase64 = (file) => new Promise((resolve) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => {
    resolve(fileReader.result);
  };
});

export const getPartnerName = (partner) => {
  if (!partner) return null;

  const { DisplayNameFr, DisplayNameEn, LegalName, PlatformPartnerName } = partner;
  const lang = getLanguage();

  if (lang === 'fr' && DisplayNameFr) return DisplayNameFr;
  if (DisplayNameEn) return DisplayNameEn;
  if (LegalName) return LegalName;
  return PlatformPartnerName;
};
